'use strict';

angular.module('enervexSalesappApp').service('AttachmentsService', function(Attachment, AttachmentComment, $stateParams) {
	this.getAttachmentVersions = getAttachmentVersions;
	this.fetchAttachments = fetchAttachments;
	this.fetchAttachment = fetchAttachment;
	this.createAttachment = createAttachment;
	this.removeAttachment = removeAttachment;
	this.updateAttachment = updateAttachment;
	this.getComments = getComments;
	this.createComment = createComment;
	this.removeComment = removeComment;
	this.updateComment = updateComment;


	function getAttachmentVersions() {
		return Attachment.versions({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.attachmentId
		});
	}

	function fetchAttachments() {
		return Attachment.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		});
	}

	function fetchAttachment() {
		return Attachment.get({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.attachmentId
		});
	}

	function createAttachment(fd) {
		console.log(fd)
		return Attachment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, fd);
	}

	function removeAttachment(attachment) {
		return Attachment.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: attachment._id
		});
	}

	function updateAttachment(fd) {
		return Attachment.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.attachmentId
		}, fd);
	}

	function getComments() {
		return AttachmentComment.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			attachmentId: $stateParams.attachmentId,
		});
	}

	function createComment(newComment) {
		return AttachmentComment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			attachmentId: $stateParams.attachmentId
		}, newComment);
	}

	function removeComment(comment) {
		return AttachmentComment.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			attachmentId: $stateParams.attachmentId,
			id: comment._id
		});
	}

	function updateComment(comment) {
		return AttachmentComment.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			attachmentId: $stateParams.attachmentId,
			id: comment._id
		}, comment);
	}
});
